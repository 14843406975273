import styled from 'styled-components';

export const Table = styled.table`
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
`;

export const TdName = styled.td`
    font-weight: bold;
    border-bottom: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
`;

export const TdValue = styled.td`
    border-bottom: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
`;
