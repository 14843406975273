export const StoreAvailabilityText = [
    'Agotado temporalmente',
    'Temporairement en rupture de stock',
    'Temporaneamente esaurito',
    'Temporariamente fora de Estoque',
    'Временно отсутствует на складе',
    '現在，在庫切れです',
    '暫時缺貨',
    'Momentan nicht auf Lager',
    '주문형 인쇄',
    'Temporarily Out of Stock'
];
