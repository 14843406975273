import { css } from "styled-components";
import { spacing8, blue10, info10, info40, text120, white } from "../../../../eden-style-constants";
const shared = css(["color:", ";& >:first-child{margin-block-start:0;}& >:last-child{margin-block-end:0;}& >:first-child >:first-child{margin-block-start:0;}& >:last-child >:last-child{margin-block-end:0;}& ol,& ul{& li:not(:last-child){margin-block-end:", ";}}& ol{list-style-type:decimal;& ol{list-style-type:upper-latin;& ol{list-style-type:upper-roman;& ol{list-style-type:lower-latin;}}}}& ul{list-style-type:disc;& ul{list-style-type:circle;& ul{list-style-type:square;}}}& a{color:", ";text-decoration:underline;}& a:hover{color:", ";text-decoration-color:", ";}"], ({
  inverted
}) => inverted ? white : text120, spacing8, ({
  inverted
}) => inverted ? blue10 : info40, ({
  inverted
}) => inverted ? white : text120, ({
  inverted
}) => inverted ? blue10 : info10);
export default shared;